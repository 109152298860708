import React, { FC } from "react"
import { Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { HasSidebarLayout, EntityHeader, DataLoad, SensorConfigurationEdit } from "src/components"
import {
  useSensorRequest,
  useEditSensorConfigurationRequest,
} from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { SensorConfiguration, SensorLastSentConfiguration } from "src/api"

const EditSensorConfiguration: FC = () => {
  const addMessage = useAddPopupMessage()
  const params = useParams()
  const { uniqueId: sensorUniqueId } = params as { uniqueId: string }
  const { data: sensor, isInitialLoading, isError } = useSensorRequest({ uniqueId: sensorUniqueId })
  document.title = `Climit Admin Panel - Edit sensor configuration ${sensor?.deviceId || ''}`
  const navigate = useNavigate()
  const request = useEditSensorConfigurationRequest({
    uniqueId: sensorUniqueId,
    options: {
      onSuccess: () => {
        addMessage({ text: "The sensor configuration was successfully sent", type: "success" })
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })

  const onSubmit = (data: SensorConfiguration) => {
    request.mutate(data)
  }

  if (!sensor) return null

  return (
    <HasSidebarLayout>
      <EntityHeader
        title={`Edit the sensor configuration, IMEI - ${sensor?.imei}`}
        onBack={() => navigate(-1)}
      />
      <DataLoad isLoading={isInitialLoading} isError={isError}>
        <Box width="500px" mt={2}>
          <SensorConfigurationEdit
            sensorConfiguration={sensor.lastSentConfig as SensorLastSentConfiguration}
            onSubmit={onSubmit}
            isLoading={request.isLoading}
            description="The displayed values represent the last sent sensor configuration"
          />
        </Box>
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default EditSensorConfiguration
