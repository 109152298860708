import React, { FC } from "react"
import {
  HasSidebarLayout,
  ListHeader,
  TabGroupFilter,
  EventTypeList,
  BuildingTypeList,
  ProjectTypeList,
  ProductTypeList,
  ConfigurationList,
  SensorParams,
  ReportTypeList,
  CombinedReportSettings,
  MobileSettingsParams,
  AlertSettingsParams,
} from "src/components"
import { SearchParamsKeys } from "src/enums"

const getUserListTabs = () => [
  {
    status: "Event Types",
    content: <EventTypeList />,
  },
  {
    status: "Building Types",
    content: <BuildingTypeList />,
  },
  {
    status: "Project Types",
    content: <ProjectTypeList />,
  },
  {
    status: "Product Categories",
    content: <ProductTypeList />,
  },
  {
    status: "Report Types",
    content: <ReportTypeList />,
  },
  {
    status: "Combined report",
    content: <CombinedReportSettings />,
  },
  {
    status: "General",
    content: <ConfigurationList />
  },
  {
    status: "Sensor",
    content: <SensorParams />
  },
  {
    status: "Mobile Settings",
    content: <MobileSettingsParams />
  },
  {
    status: "Alert Settings",
    content: <AlertSettingsParams />
  }
]

const SettingsTabs: FC = () => {
  const tabs = getUserListTabs()
  return <TabGroupFilter name={SearchParamsKeys.Tab} statusList={tabs} />
}

const SettingsList: FC = () => {
  document.title = 'Climit Admin Panel - Settings'
  return (
    <HasSidebarLayout>
      <ListHeader title="Settings" />
      <SettingsTabs />
    </HasSidebarLayout>
  )
}

export default SettingsList
