import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { Table, DynamicColumn } from 'src/components/ui'
import { SensorDashboardStatistics } from 'src/api'
import { SensorDashboardColumns } from 'src/components/columns/dashboard'

interface SensorDashboardProps {
  sensorStatistics: SensorDashboardStatistics;
}

const columns: DynamicColumn<SensorDashboardStatistics>[] = [
  SensorDashboardColumns.SensorsNumber,
  SensorDashboardColumns.FreeSensorsNumber,
  SensorDashboardColumns.AssignedSensorsNumber,
  SensorDashboardColumns.InUseSensorsNumber,
  SensorDashboardColumns.ArchivedSensorsNumber,
]

export const SensorDashboard: FC<SensorDashboardProps> = (props) => {
  const { sensorStatistics } = props
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h5">Climit Sensors</Typography>
      </Grid>
      <Grid item container>
        <Table
          columns={columns}
          data={[sensorStatistics]}
          showFooter={false}
          sx={{ width: '100%' }}
        />
      </Grid>
    </Grid>

  )
}
