import React, { FC, useCallback, useState } from "react"
import { Box } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { HasSidebarLayout, EntityHeader, DataLoad } from "src/components"
import { useEditContractorRequest, useContractorRequest, useLabelListRequest } from "src/hooks/api"
import { useAddPopupMessage } from "src/hooks/ui"
import {
  ContractorEditForm,
  ContractorEditState,
  ContractorType,
} from "src/components/forms/contractor/edit"
import { Contractor } from "src/api"

const EditContractor: FC = () => {
  const addMessage = useAddPopupMessage()
  const params = useParams()
  const { id } = params as { id: string }
  const { data: contractorResponse, isInitialLoading, isError, error } = useContractorRequest({
    id: parseInt(id, 10),
  })
  document.title = `Climit Admin Panel - Edit contractor ${contractorResponse?.name || ''}`
  const navigate = useNavigate()
  const request = useEditContractorRequest({
    id: parseInt(id, 10),
    options: {
      onSuccess: () => {
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })
  const { data: labelResponse, refetch: refetchLabels } = useLabelListRequest()
  const [isRemoved, setIsRemoved] = useState<boolean>(false)
  const contractorType = useCallback((): ContractorType => {
    if (contractorResponse?.isRoot === true) {
      return 'root'
    }
    if (contractorResponse?.isRoot === false && typeof contractorResponse?.rootContractorId === 'number') {
      return 'branch'
    }
    return 'standalone'
  }, [contractorResponse])()

  const initialValues: ContractorEditState | null = contractorResponse
    ? {
      name: contractorResponse.name,
      address1: contractorResponse.address1,
      email: contractorResponse.email,
      phone: contractorResponse.phone,
      comment: contractorResponse.comment,
      labels: contractorResponse.labels,
      link: contractorResponse.photo,
      type: contractorType,
      rootContractorId: contractorResponse?.rootContractorId,
      isRoot: contractorResponse.isRoot,
      isActiveDashboard: contractorResponse.isActiveDashboard,
      isEnabledAppBranding: contractorResponse.isEnabledAppBranding,
      isEnabledWagnerDeviceFirmwareUpdate: contractorResponse.isEnabledWagnerDeviceFirmwareUpdate,
    }
    : null

  const onSubmit = (data: ContractorEditState, photo: File | null) => {
    if (photo) {
      request.mutate({ ...data, photos: [photo] })
    } else {
      request.mutate({ ...data, photos: isRemoved ? null : undefined})
    }
  }
  return (
    <HasSidebarLayout>
      <EntityHeader title="Edit Contractor" />
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <Box width="1000px" mt={2}>
          <ContractorEditForm
            contractor={contractorResponse as Contractor}
            initialValues={initialValues}
            onSubmit={onSubmit}
            isLoading={request.isLoading}
            labels={labelResponse?.rows || []}
            refetchLabels={refetchLabels}
            setIsRemoved={setIsRemoved}
          />
        </Box>
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default EditContractor
