import { FC, useEffect } from "react"
import { Grid } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import {
  HasSidebarLayout,
  ListHeader,
  DocumentList,
} from "src/components"
import { Document } from "src/api"
import { useDocumentListRequest } from "src/hooks/api"
import { useAddPopupMessage, useQueryParams, useQueryParamsState } from "src/hooks/ui"

interface DocumentsViewProps {
  documents: Document[]
  documentCount: number
  isLoading: boolean
  isError: boolean
  errorMsg?: string
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  columns?: GridColDef<Document>[]
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
  sort: 'createdAt:desc'
}

const DocumentsView: FC<DocumentsViewProps> = ({
  documentCount,
  documents,
  isLoading,
  queryParams,
  isError,
  errorMsg,
  setQueryParams,
}) => {
  const addPopupMessage = useAddPopupMessage()
  useEffect(() => {
    if (isError && errorMsg) {
      addPopupMessage({ text: errorMsg, type: 'error' })
    }
  }, [isError, errorMsg, addPopupMessage])

  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Documents" />
      </Grid>
      <DocumentList
        documents={documents}
        documentCount={documentCount}
        isLoadingDocuments={isLoading}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        autoHeight={false}
      />
    </>
  )
}

const Documents: FC = () => {
  document.title = 'Climit Admin Panel - Documents'
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useDocumentListRequest({
    params,
    options: {
      enabled: true,
    }
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  return (
    <HasSidebarLayout>
      <DocumentsView
        documents={data?.rows || []}
        documentCount={data?.count || 0}
        isLoading={isInitialLoading}
        isError={isError}
        errorMsg={error?.message}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
      />
    </HasSidebarLayout>
  )
}

export default Documents
