import { FC, PropsWithChildren } from "react"
import { Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  SearchForm,
  ProjectListFilters,
  PropsInjector,
} from "src/components"
import {
  useProjectListRequest,
  useProjectTypeListRequest,
  useFilteredContractorListRequest,
  useContractorRequest,
} from "src/hooks/api"
import { useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { Project } from "src/api"
import { SearchParamsKeys } from "src/enums"
import { NewProjectList } from "src/components/sections/project/newProjectList"
import { defaultRowsValuePerPage } from "src/config"

const FiltersDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const queryParams = useQueryParamsState()

  const contractorId = queryParams[SearchParamsKeys.Contractor]

  const currentContractorRequest = useContractorRequest({
    id: parseInt(contractorId, 10),
    options: {
      enabled: Boolean(contractorId),
    },
  })

  const projectTypesRequest = useProjectTypeListRequest({
    params: { limit: 9999 },
  })

  const contractorsRequest = useFilteredContractorListRequest()

  const findContractorsByName = (name: string) => {
    if (name?.length >= 3) contractorsRequest.mutate({ name })
  }

  const isLoading =
    projectTypesRequest.isInitialLoading || currentContractorRequest.isInitialLoading
  const isError =
    projectTypesRequest.isError ||
    contractorsRequest.isError ||
    currentContractorRequest.isError
  const error =
    projectTypesRequest.error ||
    contractorsRequest.error ||
    currentContractorRequest.error

  const props = {
    projectTypes: projectTypesRequest.data?.rows || [],
    contractors: contractorsRequest.data?.rows || [],
    contractorsLoading: contractorsRequest.isLoading,
    currentContractor: currentContractorRequest?.data || null,
    findContractorsByName,
  }

  return (
    <DataLoad
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface ProjectsViewProps {
  projects?: Project[];
  projectsCount?: number;
  projectsLoading?: boolean;
  isLoading?: boolean;
  queryParams?: Record<string, string>;
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
  defaultRowsPerPageOptions?: number[]
}

const ProjectsView: FC<ProjectsViewProps> = ({
  projects = [],
  projectsCount = 0,
  isLoading = false,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions
}) => {
  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Projects" />
      </Grid>
      <Grid item container direction="column" alignItems="stretch" spacing={2}>
        <Grid item>
          <SearchForm />
        </Grid>
        <Grid item>
          <FiltersDataLoader>
            <ProjectListFilters />
          </FiltersDataLoader>
        </Grid>
      </Grid>
      <NewProjectList
        isLoading={isLoading}
        data={projects}
        count={projectsCount}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        defaultRowsPerPageOptions={defaultRowsPerPageOptions}
      />
    </>
  )
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ProjectDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useProjectListRequest({
    params,
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const props = {
    projects: data?.rows || [],
    projectsCount: data?.count || 0,
    projectsLoading: isInitialLoading,
    isLoading: isInitialLoading,
    queryParams,
    setQueryParams,
    defaultRowsPerPageOptions
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const Projects: FC = () => {
  document.title = 'Climit Admin Panel - Projects'
  return (
    <HasSidebarLayout>
      <ProjectDataLoader>
        <ProjectsView />
      </ProjectDataLoader>
    </HasSidebarLayout>
  )
}

export default Projects
