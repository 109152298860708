import { FC, useEffect } from "react"
import { Box, Grid } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import {
  HasSidebarLayout,
  ListHeader,
  AlertList,
  AlertFilters,
} from "src/components"
import { Alert } from "src/api"
import { useAddPopupMessage, useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { useAlertListRequest } from "src/hooks/api/useAlertList"

interface AlertsViewProps {
  alerts: Alert[]
  alertCount: number
  isLoading: boolean
  isError: boolean
  errorMsg?: string
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  columns?: GridColDef<Alert>[]
}

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
  sort: 'createdAt:desc'
}

const AlertsView: FC<AlertsViewProps> = ({
  alertCount,
  alerts,
  isLoading,
  queryParams,
  isError,
  errorMsg,
  setQueryParams,
}) => {
  const addPopupMessage = useAddPopupMessage()
  useEffect(() => {
    if (isError && errorMsg) {
      addPopupMessage({ text: errorMsg, type: 'error' })
    }
  }, [isError, errorMsg, addPopupMessage])

  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Alerts" />
      </Grid>
      <Box mb={1}>
        <AlertFilters />
      </Box>
      <AlertList
        alerts={alerts}
        alertCount={alertCount}
        isLoadingAlerts={isLoading}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        autoHeight={false}
      />
    </>
  )
}

const Alerts: FC = () => {
  document.title = 'Climit Admin Panel - Alerts'
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useAlertListRequest({
    params,
    options: {
      enabled: true,
    }
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  return (
    <HasSidebarLayout>
      <AlertsView
        alerts={data?.rows || []}
        alertCount={data?.count || 0}
        isLoading={isInitialLoading}
        isError={isError}
        errorMsg={error?.message}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
      />
    </HasSidebarLayout>
  )
}

export default Alerts
