import { FC, PropsWithChildren } from "react"
import { Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  PropsInjector,
  TaskList,
  ImagesViewerStateProvider,
  TaskFilters,
} from "src/components"
import { useTaskListRequest } from "src/hooks/api"
import { useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { Task } from "src/api"
import { defaultRowsValuePerPage } from "src/config"

interface TasksViewProps {
  tasks?: Task[];
  tasksCount?: number;
  isLoading?: boolean;
  queryParams?: Record<string, string>;
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
  defaultRowsPerPageOptions?: number[]
}

const TasksView: FC<TasksViewProps> = ({
  tasks = [],
  tasksCount = 0,
  isLoading = false,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions
}) => {
  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Tasks" />
      </Grid>
      <TaskFilters />
      <ImagesViewerStateProvider>
        <TaskList
          isLoading={isLoading}
          data={tasks}
          count={tasksCount}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          defaultRowsPerPageOptions={defaultRowsPerPageOptions}
        />
      </ImagesViewerStateProvider>
    </>
  )
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ReportDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useTaskListRequest({
    params,
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const props: TasksViewProps = {
    tasks: data?.rows || [],
    tasksCount: data?.count || 0,
    isLoading: isInitialLoading,
    queryParams,
    setQueryParams,
    defaultRowsPerPageOptions
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const Tasks: FC = () => {
  document.title = 'Climit Admin Panel - Tasks'
  return (
    <HasSidebarLayout>
      <ReportDataLoader>
        <TasksView />
      </ReportDataLoader>
    </HasSidebarLayout>
  )
}

export default Tasks
