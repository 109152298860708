import React, { FC, useMemo, useState } from 'react'
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import { AutocompleteTextField, FormCheckbox } from 'src/components/ui'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys, SensorFilters } from 'src/enums'
import { Contractor } from 'src/api'
import { useSensorFirmwareVersions } from 'src/hooks/api'

interface SensorListFiltersProps {
  currentContractor?: Contractor | null;
  contractors?: Contractor[];
  contractorsLoading?: boolean;
  findContractorsByName?: (name: string) => void;
  activeFilters?: SensorFilters[];
}

export const SensorListFilters: FC<SensorListFiltersProps> = ({
  contractors = [],
  contractorsLoading = false,
  findContractorsByName = () => {},
  currentContractor = null,
  activeFilters = [],
}) => {
  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams()
  const { data } = useSensorFirmwareVersions()
  const sensorFirmwareVersions = useMemo(() => {
    const list = data || []
    return [{ name: 'All', value: 'all' }, ...list.map((item) => ({ name: item, value: item }))]
  }, [data])
  const [hasContractorFilter] = useState(
    activeFilters.indexOf(SensorFilters.Contractor) !== -1
  )
  const [hasBatteryLevelFilter] = useState(
    activeFilters.indexOf(SensorFilters.LowBattery) !== -1
  )
  const [hasSignalLevelFilter] = useState(
    activeFilters.indexOf(SensorFilters.WeakSignal) !== -1
  )
  const [hasLastPingFilter] = useState(
    activeFilters.indexOf(SensorFilters.LastPingMoreThanHour) !== -1
  )
  const [hasSyncPeriodFilter] = useState(
    activeFilters.indexOf(SensorFilters.SyncPeriod) !== -1
  )
  const [hasReadingsAgeFilter] = useState(
    activeFilters.indexOf(SensorFilters.ReadingsAge) !== -1
  )
  const [hasAvailabilityInSystemFilter] = useState(
    activeFilters.indexOf(SensorFilters.AvailabilityInSystem) !== -1
  )
  const [hasSensorCurrentConfigFilter] = useState(
    activeFilters.indexOf(SensorFilters.CurrentSensorConfiguration) !== -1
  )
  const [hasBleStatusFilter] = useState(
    activeFilters.indexOf(SensorFilters.BleStatus) !== -1
  )
  const [hasSensorFirmwareVersionFilter] = useState(
    activeFilters.indexOf(SensorFilters.FirmwareVersion) !== -1
  )

  const formik = useFormik({
    initialValues: {
      ...(hasContractorFilter
        ? {
          [SearchParamsKeys.Contractor]:
              queryParams[SearchParamsKeys.Contractor] || null,
        }
        : null),
      ...(hasBatteryLevelFilter
        ? {
          [SearchParamsKeys.LowBattery]:
              queryParams[SearchParamsKeys.LowBattery] || false,
        }
        : null),
      ...(hasSignalLevelFilter
        ? {
          [SearchParamsKeys.WeakSignal]:
              queryParams[SearchParamsKeys.WeakSignal] || false,
        }
        : null),
      ...(hasLastPingFilter
        ? {
          [SearchParamsKeys.LastPingMoreThanHour]:
              queryParams[SearchParamsKeys.LastPingMoreThanHour] || false,
        }
        : null),
      ...(hasSyncPeriodFilter
        ? {
          [SearchParamsKeys.SyncPeriodLessThanHour]:
              queryParams[SearchParamsKeys.SyncPeriodLessThanHour] || false,
        }
        : null),
      ...(hasReadingsAgeFilter
        ? {
          [SearchParamsKeys.AgeOfReadingsMoreThanHour]:
              queryParams[SearchParamsKeys.AgeOfReadingsMoreThanHour]?.split(',') || [],
        }
        : null),
      ...(hasAvailabilityInSystemFilter
        ? {
          [SearchParamsKeys.IsAvailableInSystem]:
              queryParams[SearchParamsKeys.IsAvailableInSystem] || null,
        }
        : null),
      ...(hasSensorCurrentConfigFilter
        ? {
          [SearchParamsKeys.CurrentSensorConfiguration]:
              queryParams[SearchParamsKeys.CurrentSensorConfiguration] || null,
        }
        : null),
      ...(hasBleStatusFilter
        ? {
          [SearchParamsKeys.IsBleEnabled]:
              queryParams[SearchParamsKeys.IsBleEnabled] || 'all',
        } : null
      ),
      ...(hasSensorFirmwareVersionFilter
        ? {
          [SearchParamsKeys.SensorFirmwareVersion]:
              queryParams[SearchParamsKeys.SensorFirmwareVersion] || 'all',
        }
        : null
      ),
    },
    onSubmit: async (formState) => {
      const contractor = formState[SearchParamsKeys.Contractor] || null
      const batteryLevel = formState[SearchParamsKeys.LowBattery] || false
      const signalStrength = formState[SearchParamsKeys.WeakSignal] || false
      const lastPing = formState[SearchParamsKeys.LastPingMoreThanHour] || false
      const syncPeriod = formState[SearchParamsKeys.SyncPeriodLessThanHour] || false
      const readingsAge = formState[SearchParamsKeys.AgeOfReadingsMoreThanHour] || []
      const isAvailableInSystem = formState[SearchParamsKeys.IsAvailableInSystem] || null
      const sensorCurrentConfiguration = formState[SearchParamsKeys.CurrentSensorConfiguration] || null
      const bleStatus = formState[SearchParamsKeys.IsBleEnabled] === 'all' ? null : formState[SearchParamsKeys.IsBleEnabled]
      const firmwareVersion = formState[SearchParamsKeys.SensorFirmwareVersion] === 'all' ? null : formState[SearchParamsKeys.SensorFirmwareVersion]
      await setQueryParams(
        {
          ...(contractor && hasContractorFilter
            ? { [SearchParamsKeys.Contractor]: `${contractor}` }
            : {}),
          ...(batteryLevel && hasBatteryLevelFilter
            ? { [SensorFilters.LowBattery]: `${batteryLevel}` }
            : {}),
          ...(signalStrength && hasSignalLevelFilter
            ? { [SensorFilters.WeakSignal]: `${signalStrength}` }
            : {}),
          ...(lastPing && hasLastPingFilter
            ? { [SearchParamsKeys.LastPingMoreThanHour]: `${lastPing}` }
            : {}),
          ...(syncPeriod && hasSyncPeriodFilter
            ? { [SearchParamsKeys.SyncPeriodLessThanHour]: `${syncPeriod}` }
            : {}),
          ...(readingsAge.length > 0 && hasReadingsAgeFilter
            ? { [SearchParamsKeys.AgeOfReadingsMoreThanHour]: readingsAge.join(',') }
            : {}),
          ...(isAvailableInSystem && hasAvailabilityInSystemFilter
            ? { [SearchParamsKeys.IsAvailableInSystem]: `${isAvailableInSystem}` }
            : {}),
          ...(sensorCurrentConfiguration && hasSensorCurrentConfigFilter
            ? { [SearchParamsKeys.CurrentSensorConfiguration]: `${sensorCurrentConfiguration}` }
            : {}),
          ...(bleStatus && hasBleStatusFilter
            ? { [SearchParamsKeys.IsBleEnabled]: `${bleStatus}` }
            : {}),
          ...(firmwareVersion && hasSensorFirmwareVersionFilter
            ? { [SearchParamsKeys.SensorFirmwareVersion]: `${firmwareVersion}` }
            : {}),
        },
        [
          SearchParamsKeys.Type,
          SearchParamsKeys.Contractor,
          SearchParamsKeys.LowBattery,
          SearchParamsKeys.WeakSignal,
          SearchParamsKeys.LastPingMoreThanHour,
          SearchParamsKeys.SyncPeriodLessThanHour,
          SearchParamsKeys.AgeOfReadingsMoreThanHour,
        ]
      )
    },
  })

  const { submitForm, values, setValues, setFieldValue } = formik
  const readings = [
    { name: 'Acceleration', value: 'accelerationTime' },
    { name: 'Battery', value: 'batteryTime' },
    { name: 'Dew Point', value: 'dewpointTime' },
    { name: 'Humidity', value: 'humidityTime' },
    { name: 'Light', value: 'lightTime' },
    { name: 'Location Cellular', value: 'locationTime' },
    { name: 'Location GPS', value: 'locationGpsTime' },
    { name: 'Orientation', value: 'orientationTime' },
    { name: 'Pressure', value: 'pressureTime' },
    { name: 'Signal Strength', value: 'signalStrengthTime' },
    { name: 'Temperature', value: 'temperatureTime' },
  ]
  const availabilityInSystemOptions = [
    { name: 'All', value: null },
    { name: 'Available', value: true },
    { name: 'Not available', value: false },
  ]
  const sensorCurrentConfigurationOptions = [
    { name: 'All', value: 'all' },
    { name: 'Default', value: 'default' },
    { name: 'Custom', value: 'custom' },
  ]
  const bleStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Enabled', value: 'true' },
    { name: 'Disabled', value: 'false' },
  ]
  const handleSelectChange = (event: SelectChangeEvent<typeof values['ageOfReadingsMoreThanHour']>) => {
    const { target: { value } } = event
    setValues({
      ageOfReadingsMoreThanHour: typeof value === 'string' ? value.split(',') : value,
    })
  }
  const handleSelectClose = () => submitForm()
  const handleAvailabilityChanges = (event: SelectChangeEvent<typeof values['isAvailableInSystem']>) => {
    const { target: { value } } = event
    setValues({
      isAvailableInSystem: `${value}`,
      ageOfReadingsMoreThanHour: queryParams[SearchParamsKeys.AgeOfReadingsMoreThanHour]?.split(',') || []
    })
    submitForm()
  }
  const handleSensorCurrentConfigChanges = (event: SelectChangeEvent<typeof values['currentSensorConfiguration']>) => {
    const { target: { value } } = event
    setValues({
      currentSensorConfiguration: value,
      ageOfReadingsMoreThanHour: queryParams[SearchParamsKeys.AgeOfReadingsMoreThanHour]?.split(',') || []
    })
    submitForm()
  }
  const handleBleStatusChanges = async (event: SelectChangeEvent<typeof values['isBleEnabled']>) => {
    const { target: { value } } = event
    let _value = value
    setFieldValue(SearchParamsKeys.IsBleEnabled, _value)
    if (value === 'all') {
      await removeQueryParams([SearchParamsKeys.IsBleEnabled])
    } else {
      submitForm()
    }
  }
  const handleSensorFirmwareVersionChanges = async (event: SelectChangeEvent<typeof values['sensorFirmwareVersion']>) => {
    const { target: { value } } = event
    let _value = value
    setFieldValue(SearchParamsKeys.SensorFirmwareVersion, _value)
    if (value === 'all') {
      await removeQueryParams([SearchParamsKeys.SensorFirmwareVersion])
    } else {
      submitForm()
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container alignItems="center" spacing={4} sx={{ minHeight: 103 }}>
          {hasBatteryLevelFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Low battery"
                name={SearchParamsKeys.LowBattery}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasSignalLevelFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Weak signal"
                name={SearchParamsKeys.WeakSignal}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasLastPingFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Last ping more than hour ago"
                name={SearchParamsKeys.LastPingMoreThanHour}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasSyncPeriodFilter && (
            <Grid item>
              <FormCheckbox
                view="switch"
                label="Synchronization period less than hour"
                name={SearchParamsKeys.SyncPeriodLessThanHour}
                onChange={submitForm}
              />
            </Grid>
          )}
          {hasContractorFilter && (
            <Grid item>
              <AutocompleteTextField
                label="Contractor"
                name={SearchParamsKeys.Contractor}
                labelField="name"
                valueField="id"
                options={contractors}
                onInputChange={findContractorsByName}
                isLoading={contractorsLoading}
                onChange={submitForm}
                initialValue={currentContractor}
              />
            </Grid>
          )}
          {hasReadingsAgeFilter && (
            <Grid item>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="readings-multiple-chip-label">Readings</InputLabel>
                <Select
                  labelId="readings-multiple-chip-label"
                  id="readings-multiple-chip"
                  multiple
                  value={values.ageOfReadingsMoreThanHour}
                  onChange={handleSelectChange}
                  onClose={handleSelectClose}
                  input={<OutlinedInput id="select-multiple-chip" label="Readings" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        const reading = readings.find((item) => item?.value === value)
                        return <Chip key={reading?.value} label={reading?.name} />
                      })}
                    </Box>
                  )}
                >
                  {
                    readings.map((reading) => (
                      <MenuItem
                        key={reading?.value}
                        value={reading?.value}
                      >
                        {reading?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasAvailabilityInSystemFilter && (
            <Grid item>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="availability-in-system-chip-label">Availability in the system</InputLabel>
                <Select
                  labelId="availability-in-system-chip-label"
                  id="availability-in-system-chip"
                  value={values[SearchParamsKeys.IsAvailableInSystem] || ''}
                  onChange={handleAvailabilityChanges}
                  input={<OutlinedInput id="select-chip" label="Availability in the system" />}
                  renderValue={(selected) => {
                    const availabilityItem = availabilityInSystemOptions.find((item) => `${item.value}` === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={`${availabilityItem?.value}`} label={availabilityItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    availabilityInSystemOptions.map((availabilityItem) => (
                      <MenuItem
                        key={`${availabilityItem?.value}`}
                        value={`${availabilityItem?.value}`}
                      >
                        {availabilityItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasSensorCurrentConfigFilter && (
            <Grid item>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="sensor-current-config-chip-label">Sensor current configuration</InputLabel>
                <Select
                  labelId="sensor-current-config-chip-label"
                  id="sensor-current-config-chip"
                  value={values[SearchParamsKeys.CurrentSensorConfiguration] || ''}
                  onChange={handleSensorCurrentConfigChanges}
                  input={<OutlinedInput id="sensor-current-config-select-chip" label="Sensor current configuration" />}
                  renderValue={(selected) => {
                    const sensorCurrentConfigItem = sensorCurrentConfigurationOptions.find((item) => `${item.value}` === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={`${sensorCurrentConfigItem?.value}`} label={sensorCurrentConfigItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    sensorCurrentConfigurationOptions.map((sensorCurrentConfigItem) => (
                      <MenuItem
                        key={`${sensorCurrentConfigItem?.value}`}
                        value={`${sensorCurrentConfigItem?.value}`}
                      >
                        {sensorCurrentConfigItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasBleStatusFilter && (
            <Grid item>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="ble-status-chip-label">BLE status</InputLabel>
                <Select
                  labelId="ble-status-chip-label"
                  id="ble-status-chip"
                  value={values[SearchParamsKeys.IsBleEnabled] || ''}
                  onChange={handleBleStatusChanges}
                  input={<OutlinedInput id="ble-status-select-chip" label="BLE status" />}
                  renderValue={(selected) => {
                    const bleStatusItem = bleStatusOptions.find((item) => `${item.value}` === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={`${bleStatusItem?.value}`} label={bleStatusItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    bleStatusOptions.map((bleStatusItem) => (
                      <MenuItem
                        key={`${bleStatusItem?.value}`}
                        value={`${bleStatusItem?.value}`}
                      >
                        {bleStatusItem?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
          {hasSensorFirmwareVersionFilter && (
            <Grid item>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="sensor-firmware-version-chip-label">Sensor firmware version</InputLabel>
                <Select
                  labelId="sensor-firmware-version-chip-label"
                  id="sensor-firmware-version-chip"
                  value={values[SearchParamsKeys.SensorFirmwareVersion] || ''}
                  onChange={handleSensorFirmwareVersionChanges}
                  input={<OutlinedInput id="sensor-firmware-version-select-chip" label="Sensor firmware version" />}
                  renderValue={(selected) => {
                    const sensorFirmwareVersionItem = sensorFirmwareVersions.find((item) => item.value === selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={sensorFirmwareVersionItem?.value} label={sensorFirmwareVersionItem?.name} />
                      </Box>
                    )
                  }}
                >
                  {
                    sensorFirmwareVersions.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                      >
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  )
}
