import React, { FC } from "react"
import { useParams } from "react-router-dom"
import { Box, Grid, Typography } from "@mui/material"
import { DataLoad, EntityHeader, HasSidebarLayout, ListHeader } from "src/components"
import { useReleaseSingleRequest } from "src/hooks/api"
import { Release } from "src/api"
import { ReleaseEditForm } from "src/components/forms/release"

interface ReleaseListProps {
  release: Release | null
}

const ReleaseEdit: FC<ReleaseListProps> = ({
  release,
}) => {
  if (!release) {
    return (
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="What’s new release notes" />
        <Grid container mt={2}>
          <Typography variant="body2">
            Release not found
          </Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container direction="column" alignItems="stretch">
      <EntityHeader title="Edit release notes" />
      <Box width="500px" mt={2}>
        <ReleaseEditForm release={release} />
      </Box>
    </Grid>
  )
}

const ReleaseEditLoader: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }
  const { isInitialLoading, isError, error, data } = useReleaseSingleRequest({ id: parseInt(id, 10) })
  const release = data || null
  document.title = `Climit Admin Panel - Edit release ${release?.description || ''}`

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message || ''}
      >
        <ReleaseEdit
          release={release}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ReleaseEditLoader
