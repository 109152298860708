import React, { FC, useCallback, useEffect, useState } from "react"
import { Grid, Paper, Menu, MenuItem } from "@mui/material"
import ImagesViewer from "react-images-viewer"
import {
  DataGridPremium,
  GridPaginationModel,
  GridPinnedColumns,
} from '@mui/x-data-grid-premium'
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  productRequestTableColumns,
  ImagesViewerStateProvider,
  TableToolbar,
} from "src/components"
import { useEditProductRequestRequest, useNewProductRequestNumberRequest, useProductRequestListRequest, useProductRequestSendSummaryRequest, useProductRequestStatusListRequest } from "src/hooks/api"
import { ProductRequest, ProductRequestStatus, ProductRequestSummaryPayload } from "src/api"
import { useAddPopupMessage, useQueryParams, useDropdownItemFactory, useNewProductRequestNumber, useDialog, useImagesViewer } from "src/hooks/ui"
import { DialogNames, Entities, SearchParamsKeys } from "src/enums"
import { useQueryClient } from '@tanstack/react-query'
import { ProductRequestSendSummaryModal } from "src/components/sections/productRequest/sendSummaryModal"
import { defaultRowsValuePerPage } from "src/config"

interface ProductRequestListContentProps {
  productRequests: ProductRequest[];
  productRequestCount: number;
  productRequestStatuses: ProductRequestStatus[];
  isLoading?: boolean;
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
}
export type ImagesListItem = {
  src: string
}

const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ProductRequestListContent: FC<ProductRequestListContentProps> = ({
  productRequests,
  productRequestCount,
  productRequestStatuses,
  setQueryParams,
  queryParams,
  isLoading = false,
}) => {
  const [rowCountState, setRowCountState] = useState(productRequestCount)
  const [updatedProductRequest, setUpdatedProductRequest] = useState<ProductRequest | null>(null)
  const [currentRow, setCurrentRow] = useState<ProductRequest | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const {
    isOpen: isOpenedGallery,
    images,
    currentImageIndex,
    openGallery,
    closeGallery,
    goToNext,
    goToPrev,
  } = useImagesViewer()
  const addMessage = useAddPopupMessage()
  const queryClient = useQueryClient()
  const { setNewProductRequestNumber } = useNewProductRequestNumber()
  const udpateProductRequest = useEditProductRequestRequest()
  const sendProductRequestSummaryRequest = useProductRequestSendSummaryRequest()
  const { refetch: refetchNewProductRequestNumber } = useNewProductRequestNumberRequest({
    options: {
      enabled: false,
      onSuccess: setNewProductRequestNumber,
    },
  })
  const statusDropdownItemListMap = useDropdownItemFactory({
    params: { labelProp: 'displayName', valueProp: 'id' },
  })
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: ProductRequest
  ) => {
    setCurrentRow(row)
    setUpdatedProductRequest(row)
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }
  const { openDialog: openProductRequestSummaryDialog } = useDialog(DialogNames.ProductRequestSummary)
  const { closeDialog: closeProductRequestConfirmationDialog } = useDialog(DialogNames.ProductRequestSummaryConfirmation)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [],
    right: ["actions"]
  })
  const columns = productRequestTableColumns({
    statuses: productRequestStatuses,
    statusDropdownItemListMap,
    handleOpenMenu,
    openGallery,
  })
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      productRequestCount !== undefined ? productRequestCount : prevRowCountState
    )
  }, [productRequestCount, setRowCountState])
  
  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const updateNewProductRequestNumber = useCallback(async () => {
    queryClient.invalidateQueries([Entities.ProductRequest, 'number-of-new'])
    await refetchNewProductRequestNumber()
  }, [refetchNewProductRequestNumber, queryClient])

  const isDoneProductRequestStatus = useCallback((statusId: number | null | undefined): boolean => {
    if (!statusId) return false
    const status = productRequestStatuses.find((item) => item.id === statusId)
    return status?.name === 'done'
  }, [productRequestStatuses])

  const isSentProductRequestSummary = useCallback((productRequest?: ProductRequest) => {
    const _productRequest = productRequest || currentRow
    return _productRequest?.doneResponseStatus === 'sent'
  }, [currentRow])

  const sendProductRequestSummary = (
    payload: ProductRequestSummaryPayload & { productRequestId: number },
  ) => {
    sendProductRequestSummaryRequest.mutate(payload, {
      onSuccess: () => {
        closeProductRequestConfirmationDialog()
        addMessage({ text: 'The message has been sent successfully', type: 'success' })
      },
      onError: ({ message }) => {
        addMessage({ text: message, type: 'error' })
      }
    })
  }

  const processRowUpdate = useCallback(async (
    newRow: ProductRequest,
    oldRow: ProductRequest,
  ) => {
    const { comment: newComment, statusId: newStatusId } = newRow
    const { comment: oldComment, statusId: oldStatusId } = oldRow
    const isCommentChanged = newComment !== oldComment
    const isStatusChanged = newStatusId !== oldStatusId
    const updatedRow: ProductRequest = await new Promise((resolve) => {
      if (isCommentChanged || isStatusChanged) {
        udpateProductRequest.mutate({
          id: newRow.id,
          payload: {
            ...(newComment ? { comment: newComment } : {}),
            ...(newStatusId ? { statusId: newStatusId } : {}),
          },
        }, {
          onSuccess: () => {
            addMessage({ text: "The product request updated", type: "success" })
            if (isStatusChanged) updateNewProductRequestNumber()
            resolve(newRow)
          },
          onError: (error) => {
            addMessage({ text: error?.message, type: "error" })
            resolve(oldRow)
          }
        })
      }
      resolve(newRow)
    })
    setUpdatedProductRequest(updatedRow)
    if (
      isStatusChanged
      && isDoneProductRequestStatus(updatedRow.statusId)
      && !isSentProductRequestSummary(updatedRow)
    ) {
      openProductRequestSummaryDialog()
    }
    return updatedRow
  }, [
    addMessage,
    udpateProductRequest,
    updateNewProductRequestNumber,
    isDoneProductRequestStatus,
    openProductRequestSummaryDialog,
    isSentProductRequestSummary,
  ])

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%', width: '100%' }}>
      <DataGridPremium<ProductRequest>
        pagination
        autoHeight={false}
        disableRowSelectionOnClick
        rows={productRequests}
        columns={columns}
        pinnedColumns={pinnedColumns}
        rowCount={rowCountState}
        loading={isLoading}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: TableToolbar
        }}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
      <ProductRequestSendSummaryModal
        isLoading={sendProductRequestSummaryRequest.isLoading}
        productRequest={updatedProductRequest as ProductRequest}
        onSendSummary={sendProductRequestSummary}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={!isDoneProductRequestStatus(currentRow?.statusId) || isSentProductRequestSummary()}
          onClick={() => {
            openProductRequestSummaryDialog()
            handleCloseMenu()
          }}
        >
          Send summary
        </MenuItem>
      </Menu>
      <ImagesViewer
        imgs={images}
        isOpen={isOpenedGallery}
        onClickPrev={goToPrev}
        onClickNext={goToNext}
        onClose={closeGallery}
        currImg={currentImageIndex}
      />
    </Paper>
  )
}

const ProductRequestList: FC = () => {
  document.title = 'Climit Admin Panel - Product requests'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { isInitialLoading, isError, error, data: productRequests } = useProductRequestListRequest({
    params: queryParams
  })
  const { data: productRequestStatutses } = useProductRequestStatusListRequest()

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <ListHeader title="Product requests" />
          </Grid>
        </Grid>
        <ImagesViewerStateProvider>
          <ProductRequestListContent
            productRequests={productRequests?.rows || []}
            productRequestCount={productRequests?.count || 0}
            productRequestStatuses={productRequestStatutses || []}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
          />
        </ImagesViewerStateProvider>
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ProductRequestList
