import React, { FC, useMemo, useState } from "react"
import { Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  AttachmentInfo,
} from "src/components"
import { useAttachmentRequest } from "src/hooks/api"
import { Attachment } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"

interface Props {
  attachment: Attachment | null
}

const AttachmentShowContent: FC<Props> = ({
  attachment,
}) => {
  const fileName = useMemo(() => attachment?.fileName || attachment?.originalFileName || '', [attachment])
  if (!attachment) return null

  return (
    <Grid container direction="column">
      <Grid item>
        <EntityHeader title={`Details of ${fileName}`} />
      </Grid>
      <Grid item container>
        <AttachmentInfo attachment={attachment} />
      </Grid>
    </Grid>
  )
}

const AttachmentShow: FC = () => {
  const addPopupMessage = useAddPopupMessage()
  const [attachment, setAttachment] = useState<Attachment | null>(null)
  document.title = `Climit Admin Panel - Event ${attachment?.fileName || attachment?.originalFileName || ''}`
  const params = useParams()
  const id = parseInt((params as any).id, 10)
  const { isInitialLoading, isError, error } = useAttachmentRequest({
    id,
    options: {
      onSuccess: (data) => setAttachment(data),
      onError: ({ message: text }) => addPopupMessage({ text, type: "error" }),
    },
  })

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <AttachmentShowContent
          attachment={attachment}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default AttachmentShow
