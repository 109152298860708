import { FC } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useAttachmentListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { DataLoad } from 'src/components/ui'
import { Attachment } from 'src/api'
import { ImagesViewerStateProvider } from 'src/components/providers'
import { AttachmentFilters } from 'src/enums'
import { AttachmentList } from '..'

const defaultPerPage = 50
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

type Props = {
  attachments: Attachment[]
  count: number
  isLoading: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
}

const DiagnosticsAttachmentsTab: FC<Props> = ({
  attachments,
  count,
  isLoading,
  queryParams,
  setQueryParams,
}) => {
  return (
    <AttachmentList
      attachments={attachments}
      attachmentCount={count}
      isLoadingAttachments={isLoading}
      setQueryParams={setQueryParams}
      queryParams={queryParams}
      autoHeight={false}
      activeFilters={[AttachmentFilters.Status]}
    />
  )
}

const DiagnosticsAttachmentsLoadable: FC = () => {
  document.title = 'Climit Admin Panel - Background Sync Attachments'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { data, isInitialLoading } = useAttachmentListRequest({
    params: queryParams,
  })

  return (
    <Grid container direction="column" spacing={2} height="100%" mb={10}>
      <Grid item>
        <Typography variant='h6'>Background Sync</Typography>
      </Grid>
      <Grid item width="100%">
        <ImagesViewerStateProvider>
          <DiagnosticsAttachmentsTab
            attachments={data?.rows || []}
            count={data?.count || 0}
            isLoading={isInitialLoading}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </ImagesViewerStateProvider>
      </Grid>
    </Grid>
  )
}

export {
  DiagnosticsAttachmentsLoadable as DiagnosticsAttachmentsTab,
}
