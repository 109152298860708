import { FC, useState } from "react"
import { Grid } from "@mui/material"
import { useParams } from "react-router-dom"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  ProductImportInfo,
} from "src/components"
import { useProductImportRequest } from "src/hooks/api"
import { ProductImport } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"

interface ProductImportContentProps {
  productImport: ProductImport;
}

const ProductImportContent: FC<ProductImportContentProps> = ({
  productImport,
}) => {
  if (!productImport) return null

  const { fileName } = productImport
  const title = `Product import ${fileName || ''}`
  return (
    <Grid container direction="column">
      <Grid item>
        <EntityHeader title={title} />
      </Grid>
      <ProductImportInfo productImport={productImport} />
    </Grid>
  )
}

const EventShow: FC = () => {
  const addPopupMessage = useAddPopupMessage()
  const [productImport, setProductImport] = useState<ProductImport>()
  document.title = `Climit Admin Panel - Product import ${productImport?.fileName || ''}`
  const params = useParams()
  const id = parseInt((params as any).id, 10)
  const { isInitialLoading, isError, error } = useProductImportRequest({
    id,
    options: {
      onSuccess: (data) => setProductImport(data as ProductImport),
      onError: ({ message: text }) => addPopupMessage({ text, type: "error" }),
    },
  })

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <ProductImportContent
          productImport={productImport as ProductImport}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default EventShow
