import React, { FC, useEffect, useState, PropsWithChildren } from "react"
import { Button, Grid, Paper } from "@mui/material"
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
  GridState,
} from '@mui/x-data-grid-premium'
import {
  HasSidebarLayout,
  ListHeader,
  DataLoad,
  InnerLink,
  PropsInjector,
  ButtonGroupFilter,
  ButtonGroupItem,
  SearchForm,
  UserListFilters,
  TableToolbar,
} from "src/components"
import {
  useUserListRequest,
  useUserRoleListRequest,
  useContractorRequest,
  useFilteredContractorListRequest,
} from "src/hooks/api"
import { useAuthorizedState, useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { User, UserRole } from "src/api"
import { config, defaultRowsValuePerPage } from 'src/config'
import { UserRoles, SearchParamsKeys, UserFilterRoles } from "src/enums"
import { UserColumnsDataGrid } from "src/components/sections/user/tableColumns"
import { buildQueryParams } from "src/helpers"

const roleFilters = {
  [UserFilterRoles.Contractor]: (list: UserRole[]) =>
    list.filter((el) => el.name !== UserRoles.Admin),
  [UserFilterRoles.Admin]: (list: UserRole[]) =>
    list.filter((el) => el.name === UserRoles.Admin),
}

const getFilter = (list: UserRole[], name: string) => {
  let filter = roleFilters[name as UserFilterRoles]
  if (!filter) filter = (_list: UserRole[]) => _list

  return filter(list).map(({ id }) => id)
}

const tabsList: ButtonGroupItem[] = [
  {
    displayName: "Contractor",
    value: UserFilterRoles.Contractor,
  },
  {
    displayName: "Admin",
    value: UserFilterRoles.Admin,
  },
]

const getUserListColumns = ({
  pmRoleId,
  ownerRoleId,
}: {
  pmRoleId: number | undefined;
  ownerRoleId: number | undefined;
}): Record<UserFilterRoles, GridColDef<User>[]> => ({
  [UserFilterRoles.Contractor]: [
    UserColumnsDataGrid.NameWithIcon(pmRoleId, ownerRoleId),
    UserColumnsDataGrid.UserStatus,
    UserColumnsDataGrid.ContractorName,
    UserColumnsDataGrid.UserRole,
    UserColumnsDataGrid.Phone,
    UserColumnsDataGrid.Email,
    UserColumnsDataGrid.LastAppVersion,
    UserColumnsDataGrid.LastActive,
    UserColumnsDataGrid.CreatedAt,
  ],
  [UserFilterRoles.Admin]: [
    UserColumnsDataGrid.NameWithIcon(pmRoleId, ownerRoleId),
    UserColumnsDataGrid.UserStatus,
    UserColumnsDataGrid.Phone,
    UserColumnsDataGrid.Email,
    UserColumnsDataGrid.LastAppVersion,
    UserColumnsDataGrid.LastActive,
    UserColumnsDataGrid.CreatedAt,
  ],
})

const UserRoleLoader: FC<PropsWithChildren> = ({ children }) => {
  const { isInitialLoading, isError, data, error } = useUserRoleListRequest({
    params: { limit: 9999 },
  })
  return (
    <DataLoad
      isLoading={isInitialLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={{ userRoles: data?.rows || [] }} >
        {children}
      </PropsInjector>
    </DataLoad>
  )
}

interface UserListLoaderProps {
  userRoles?: UserRole[];
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const UserListLoader: FC<PropsWithChildren<UserListLoaderProps>> = ({
  children,
  userRoles = [],
  ...rest
}) => {
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const currentRoleName =
    (queryParams[SearchParamsKeys.Role] as UserFilterRoles) ||
    UserFilterRoles.Contractor

  const { data, isInitialLoading, isError, error } = useUserListRequest({
    params: {
      ...queryParams,
      roleIds: getFilter(userRoles, currentRoleName),
    },
  })

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <UserList
        currentRoleName={currentRoleName}
        users={data?.rows || []}
        usersCount={data?.count || 0}
        isLoading={isInitialLoading}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </DataLoad>
  )
}

const FiltersDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const queryParams = useQueryParamsState()

  const contractorId = queryParams[SearchParamsKeys.Contractor]

  const currentContractorRequest = useContractorRequest({
    id: parseInt(contractorId, 10),
    options: {
      enabled: Boolean(contractorId),
    },
  })

  const contractorsRequest = useFilteredContractorListRequest()

  const findContractorsByName = (name: string) => {
    if (name?.length >= 3) contractorsRequest.mutate({ name })
  }

  const isLoading = currentContractorRequest.isInitialLoading
  const isError =
    contractorsRequest.isError || currentContractorRequest.isError
  const error = contractorsRequest.error || currentContractorRequest.error
  const props = {
    contractors: contractorsRequest.data?.rows || [],
    contractorsLoading: contractorsRequest.isLoading,
    currentContractor: currentContractorRequest?.data || null,
    findContractorsByName,
  }

  return (
    <DataLoad
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

interface UserListProps {
  userRoles?: UserRole[];
  currentRoleName?: UserFilterRoles;
  users?: User[];
  usersCount?: number;
  isLoading?: boolean;
  autoHeight?: boolean;
  queryParams?: Record<string, string>;
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
}

const UserList: FC<UserListProps> = ({
  userRoles = [],
  users = [],
  currentRoleName = UserFilterRoles.Contractor,
  usersCount = 0,
  isLoading = false,
  autoHeight = false,
  setQueryParams,
  queryParams
}) => {
  const pmRoleId = userRoles.find((el) => el.name === UserRoles.PM)?.id
  const ownerRoleId = userRoles.find((el) => el.name === UserRoles.Owner)?.id
  const columnsGroups = getUserListColumns({ pmRoleId, ownerRoleId })
  const columns = columnsGroups[currentRoleName]
  const [rowCountState, setRowCountState] = useState(usersCount)

  const apiRef = useGridApiRef()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      usersCount !== undefined ? usersCount : prevRowCountState
    )
  }, [usersCount, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async (state: GridState) => {
    const filterModel = gridFilterModelSelector(state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    } else {
      setRowCountState(usersCount)
    }
  }

  const page = queryParams?.page ? parseInt(queryParams.page, 10) - 1 : defaultPage - 1
  const pageSize = queryParams?.rowsPerPage ? parseInt(queryParams.rowsPerPage, 10) : defaultPerPage

  return (
    <Paper sx={{ flexGrow: 1, marginTop: 3, minHeight: '200px', height: '100%' }} >
      <DataGridPremium<User>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        disableRowSelectionOnClick
        rows={users}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: TableToolbar,
        }}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper >
  )
}

const UsersList: FC = () => {
  document.title = 'Climit Admin Panel - Users'
  const token = useAuthorizedState()
  const downloadUserData = async () => {
    const { apiPath, host } = config
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const basePath = `${host}/${apiPath}`
    const queryParams = buildQueryParams({ Authorization: token, clientTimeZone })
    const response = await fetch(`${basePath}/v1/admin/users/export-contractors-users?${queryParams}`, {
      headers: {
        'User-Agent': navigator.userAgent,
        'client-platform': 'admin'
      }
    })
    const file = await response.blob()
    const href = window.URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', `contractor_user_data_${new Date().toISOString()}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <HasSidebarLayout>
      <ListHeader title="Users">
        <Grid container spacing={2}>
          <Grid item>
            <Button onClick={downloadUserData} variant="contained">Contractor User Data Export</Button>
          </Grid>
          <Grid item>
            <InnerLink to="/users/new" style={{ textDecoration: "none" }}>
              <Button variant="contained">Add User</Button>
            </InnerLink>
          </Grid>
        </Grid>
      </ListHeader>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <SearchForm />
        </Grid>
        <Grid item container spacing={4} alignItems="stretch">
          <Grid item>
            <FiltersDataLoader>
              <UserListFilters />
            </FiltersDataLoader>
          </Grid>
          <Grid item>
            <ButtonGroupFilter name={SearchParamsKeys.Role} list={tabsList} />
          </Grid>
        </Grid>
      </Grid>
      <UserRoleLoader>
        <UserListLoader />
      </UserRoleLoader>

    </HasSidebarLayout>
  )
}

export default UsersList
