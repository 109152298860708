import React, { FC, useState, useEffect, useCallback } from "react"
import { Grid, Paper } from "@mui/material"
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  gridFilteredTopLevelRowCountSelector,
  gridFilterModelSelector,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from '@mui/x-data-grid-premium'
import { WagnerProbe } from "src/api"
import { HasSidebarLayout, ListHeader, RapidRHColumnsDataGrid, RapidRhListFilters, SearchForm, TableToolbar } from "src/components"
import { useWagnerProbeListRequest } from "src/hooks/api"
import { useQueryParams } from "src/hooks/ui"
import { RapidRhFilters, SearchParamsKeys } from "src/enums"
import { defaultRowsValuePerPage } from "src/config"

interface WagnerProbeListProps {
  wagnerProbes: WagnerProbe[]
  wagnerProbeCount: number
  isLoadingWagnerProbes: boolean
  queryParams: Record<string, string>
  setQueryParams: (params: Record<string, string>, paramsToRemove?: string[]) => void
  autoHeight?: boolean
  tableColumns?: GridColDef<WagnerProbe>[]
  exportedFileName?: string
}

const rapidRhColumns = [
  RapidRHColumnsDataGrid.ProbeId,
  RapidRHColumnsDataGrid.IsOnline,
  RapidRHColumnsDataGrid.ReadingTime,
  RapidRHColumnsDataGrid.Label,
  RapidRHColumnsDataGrid.ReaderId,
  RapidRHColumnsDataGrid.VerizonGAT,
  RapidRHColumnsDataGrid.Contractor,
  RapidRHColumnsDataGrid.Project,
  RapidRHColumnsDataGrid.ProjectSection,
  RapidRHColumnsDataGrid.LastDeviceType,
  RapidRHColumnsDataGrid.LastSignalStrength,
  RapidRHColumnsDataGrid.LastBatteryLevel,
  RapidRHColumnsDataGrid.ReceivedFromDataGrabber,
  RapidRHColumnsDataGrid.ReceivedFromMiniGrabber,
  RapidRHColumnsDataGrid.ReceivedFromTotalReader,
  RapidRHColumnsDataGrid.ReceivedFromRepeater,
  RapidRHColumnsDataGrid.ReceivedViaPhone,
  RapidRHColumnsDataGrid.ReceivedViaGAT,
  RapidRHColumnsDataGrid.CreatedAt,
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const WagnerProbeList: FC<WagnerProbeListProps> = ({
  wagnerProbeCount,
  wagnerProbes,
  isLoadingWagnerProbes,
  setQueryParams,
  queryParams,
  autoHeight = false,
  tableColumns,
  exportedFileName,
}) => {
  const apiRef = useGridApiRef()
  const columns = tableColumns && tableColumns.length > 0 ? tableColumns : rapidRhColumns
  const [rowCountState, setRowCountState] = useState(wagnerProbeCount)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "probeId"],
  })

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      wagnerProbeCount !== undefined ? wagnerProbeCount : prevRowCountState
    )
  }, [wagnerProbeCount, setRowCountState])

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(wagnerProbeCount)
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%' }}>
      <DataGridPremium<WagnerProbe>
        apiRef={apiRef}
        pagination
        autoHeight={autoHeight}
        pinnedColumns={pinnedColumns}
        disableRowSelectionOnClick
        rows={wagnerProbes}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoadingWagnerProbes}
        paginationModel={{
          page: page as number,
          pageSize: pageSize as number
        }}
        rowThreshold={2}
        columnThreshold={2}
        paginationMode="server"
        pageSizeOptions={defaultRowsPerPageOptions}
        onPaginationModelChange={handleChangePaginationModel}
        onStateChange={handleChangeState}
        localeText={{
          columnMenuSortAsc: "Sort A-Z",
          columnMenuSortDesc: "Sort Z-A",
        }}
        slots={{
          toolbar: () => <TableToolbar exportedFileName={exportedFileName} />
        }}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        sx={(theme) => ({
          border: "none",
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            paddingLeft: "5px",
            overflow: "visible",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: 1,
            fontSize: "13px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            overflow: "visible",
          },
        })}
      />
    </Paper>
  )
}

const WagnerProbeLoader: FC = () => {
  document.title = 'Climit Admin Panel - Rapid RH Probes'
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { data, isInitialLoading } = useWagnerProbeListRequest({
    params: queryParams,
  })

  return (
    <HasSidebarLayout>
      <ListHeader title="Wagner probes" />
      <Grid container direction="column" alignItems="stretch" spacing={2} sx={{ my: 2 }}>
        <Grid item>
          <SearchForm />
        </Grid>
        <Grid item>
          <RapidRhListFilters
            activeFilters={[
              RapidRhFilters.ConnectivityToProject,
            ]}
          />
        </Grid>
      </Grid>
      <WagnerProbeList
        wagnerProbeCount={data?.count || 0}
        wagnerProbes={data?.rows || []}
        isLoadingWagnerProbes={isInitialLoading}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        autoHeight={false}
      />
    </HasSidebarLayout>
  )
}

export default WagnerProbeLoader