import React, { FC } from "react"
import { Box, Grid, Typography } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  ProjectDashboard,
  SensorDashboard,
  RapidRHSensorDashboard,
  ProductDashboard,
  ManufacturerAnnouncementDashboard,
  UsageStatisticsDashboard,
  ProjectTypeDashboard,
  ProjectBuildingTypeDashboard,
  EventDashboard,
} from "src/components"
import { useDashboardRequest } from "src/hooks/api"
import { DashboardStatistics } from "src/api"

interface DashboardContentProps {
  dashboard: DashboardStatistics | null;
}

const DashboardContent: FC<DashboardContentProps> = ({
  dashboard,
}) => {
  if (!dashboard) {
    return (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="body2">There is no statistics information</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <ProjectDashboard projectStatistics={dashboard.project} />
      </Grid>
      <Grid item>
        <SensorDashboard sensorStatistics={dashboard.sensor} />
      </Grid>
      <Grid item>
        <RapidRHSensorDashboard rapidRHSensorStatistics={dashboard.rapidRHSensor} />
      </Grid>
      <Grid item>
        <ProductDashboard productStatistics={dashboard.product} />
      </Grid>
      <Grid item>
        <ManufacturerAnnouncementDashboard manufacturerAnnouncementStatistics={dashboard.manufacturerAnnouncement} />
      </Grid>
      <Grid item>
        <UsageStatisticsDashboard usageStatistics={dashboard.usageStatistics} />
      </Grid>
      <Grid item>
        <ProjectTypeDashboard projectTypeStatistics={dashboard.projectTypes} projectsNumber={dashboard.project.projectsNumber} />
      </Grid>
      <Grid item>
        <ProjectBuildingTypeDashboard projectBuildingTypeStatistics={dashboard.projectBuildingTypes} projectsNumber={dashboard.project.projectsNumber} />
      </Grid>
      <Grid item>
        <EventDashboard eventStatistics={dashboard.event} />
      </Grid>
    </Grid>
  )
}

const Dashboard: FC = () => {
  document.title = 'Climit Admin Panel - Dashboard'
  const { data, isInitialLoading, isError, error } = useDashboardRequest()

  return (
    <HasSidebarLayout>
      <Box mb={2}>
        <ListHeader title="Dashboard" />
      </Box>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message}
      >
        <DashboardContent
          dashboard={data || null}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default Dashboard
