import React, { FC, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Grid, Typography } from "@mui/material"
import { CancelManufacturerAnnouncementModal, DataLoad, EntityHeader, HasSidebarLayout, ListHeader } from "src/components"
import { useManufacturerAnnouncementCategoryListRequest, useManufacturerAnnouncementSingleRequest, useManufacturerAnnouncementTypeListRequest, useManufacturerListRequest } from "src/hooks/api"
import { Manufacturer, ManufacturerAnnouncement, ManufacturerAnnouncementCategory, ManufacturerAnnouncementType } from "src/api"
import { ManufacturerAnnouncementEditForm } from "src/components/forms"
import { useDialog } from "src/hooks/ui"
import { DialogNames } from "src/enums"

interface Props {
  announcement: ManufacturerAnnouncement | null
  announcementTypes: ManufacturerAnnouncementType[]
  announcementCategories: ManufacturerAnnouncementCategory[]
  manufacturers: Manufacturer[]
  searchManufacturer: (name: string) => void
}

const ManufacturerAnnouncementEdit: FC<Props> = ({
  announcement,
  announcementTypes,
  announcementCategories,
  manufacturers,
  searchManufacturer
}) => {
  if (!announcement) {
    return (
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="GangBox" />
        <Grid container mt={2}>
          <Typography variant="body2">
             No news found
          </Typography>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container direction="column" alignItems="stretch">
      <Box width="1000px" maxWidth="100%" mt={2}>
        <ManufacturerAnnouncementEditForm
          announcement={announcement}
          announcementTypes={announcementTypes}
          announcementCategories={announcementCategories}
          manufacturers={manufacturers}
          searchManufacturer={searchManufacturer}
        />
      </Box>
    </Grid>
  )
}

const ManufacturerAnnouncementEditLoader: FC = () => {
  const [searchText, setSearchText] = useState<string>('')
  const params = useParams()
  const navigate = useNavigate()
  const { id } = params as { id: string }
  const manufacturerAnnouncementSingleRequest = useManufacturerAnnouncementSingleRequest({ id: parseInt(id, 10) })
  const announcement = manufacturerAnnouncementSingleRequest.data || null
  document.title = `Climit Admin Panel - Edit manufacturer announcement ${announcement?.name || ''}`
  const announcementTypesRequest = useManufacturerAnnouncementTypeListRequest({
    params: {
      limit: 100
    }
  })
  const announcementCategoryRequest = useManufacturerAnnouncementCategoryListRequest({
    params: {
      limit: 100
    }
  })
  const manufacturerListRequest = useManufacturerListRequest({
    params: {
      limit: 20,
      ...(searchText ? { search: searchText } : {})
    }
  })
  const searchManufacturerByName = (name: string): void => {
    if (name.length < 3) return
    setSearchText(name)
  }
  const { openDialog: openCancelDialog, closeDialog: closeCancelDialog } = useDialog(DialogNames.CancelManufacturerAnnouncement)
  const onCancelChanges = () => {
    closeCancelDialog()
    navigate(-1)
  }
  const types = announcementTypesRequest.data?.rows || []
  const manufacturers = manufacturerListRequest.data?.rows || []
  const categories = announcementCategoryRequest.data?.rows || []

  const isLoading = manufacturerAnnouncementSingleRequest.isInitialLoading || announcementTypesRequest.isInitialLoading
  const isError = manufacturerAnnouncementSingleRequest.isError || announcementTypesRequest.isError
  const error = manufacturerAnnouncementSingleRequest.error || announcementTypesRequest.error

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message || ''}
      >
        <EntityHeader
          title="Edit post"
          onBack={openCancelDialog}
        />
        <ManufacturerAnnouncementEdit
          announcement={announcement}
          announcementTypes={types}
          announcementCategories={categories}
          manufacturers={manufacturers}
          searchManufacturer={searchManufacturerByName}
        />
      </DataLoad>
      <CancelManufacturerAnnouncementModal
        onBack={closeCancelDialog}
        onConfirm={onCancelChanges}
      />
    </HasSidebarLayout>
  )
}

export default ManufacturerAnnouncementEditLoader
