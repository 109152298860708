import React, { FC, useState } from "react"
import { Box } from "@mui/system"
import { useNavigate } from "react-router-dom"
import { CancelManufacturerAnnouncementModal, DataLoad, EntityHeader, HasSidebarLayout } from "src/components"
import { ManufacturerAnnouncementNewForm } from "src/components/forms/manufacturerAnnouncement"
import { useManufacturerAnnouncementCategoryListRequest, useManufacturerAnnouncementTypeListRequest, useManufacturerListRequest } from "src/hooks/api"
import { useDialog } from "src/hooks/ui"
import { DialogNames } from "src/enums"

export const ManufacturerAnnouncementNew: FC = () => {
  document.title = 'Climit Admin Panel - New manufacturer announcement'
  const [searchText, setSearchText] = useState<string>('')
  const navigate = useNavigate()
  const announcementTypesRequest = useManufacturerAnnouncementTypeListRequest({
    params: {
      limit: 100
    }
  })
  const announcementCategoryRequest = useManufacturerAnnouncementCategoryListRequest({
    params: {
      limit: 100
    }
  })
  const manufacturerListRequest = useManufacturerListRequest({
    params: {
      limit: 100,
      ...(searchText ? { search: searchText } : {}),
    },
  })
  const searchManufacturerByName = (name: string): void => {
    if (name.length < 2) return
    setSearchText(name)
  }
  const { openDialog: openCancelDialog, closeDialog: closeCancelDialog } = useDialog(DialogNames.CancelManufacturerAnnouncement)
  const onCancelChanges = () => {
    closeCancelDialog()
    navigate(-1)
  }
  const types = announcementTypesRequest.data?.rows || []
  const manufacturers = manufacturerListRequest.data?.rows || []
  const categories = announcementCategoryRequest.data?.rows || []

  const isLoading = announcementTypesRequest.isInitialLoading
  const isError = announcementTypesRequest.isError
  const error = announcementTypesRequest.error

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message || ''}
      >
        <EntityHeader
          title="Add new post"
          onBack={openCancelDialog}
        />
        <Box width="1000px" maxWidth="100%" mt={2}>
          <ManufacturerAnnouncementNewForm
            announcementTypes={types}
            announcementCategories={categories}
            manufacturers={manufacturers}
            searchManufacturer={searchManufacturerByName}
          />
        </Box>
      </DataLoad>
      <CancelManufacturerAnnouncementModal
        onBack={closeCancelDialog}
        onConfirm={onCancelChanges}
      />
    </HasSidebarLayout>
  )
}

export default ManufacturerAnnouncementNew
