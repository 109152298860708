import React, { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  HasSidebarLayout,
  UserEditForm,
  UserEditState,
  DataLoad,
  PropsInjector,
  EntityHeader,
} from 'src/components'
import {
  useUserRequest,
  useEditUserRequest,
  useUserRoleListRequest,
  useUserMessageChannelListRequest,
} from 'src/hooks/api'
import { useAddPopupMessage } from 'src/hooks/ui'
import { UserRoles } from 'src/enums'
import { UserMessageChannels } from 'src/api'

const UserResourceLoader: FC<PropsWithChildren<{ userId: number }>> = ({
  userId: id,
  children,
}) => {
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()

  const userRequest = useUserRequest({ id })
  document.title = `Climit Admin Panel - Edit user ${userRequest.data?.name || ''}`

  const userRoleListRequest = useUserRoleListRequest({
    params: {
      limit: 9999,
    },
  })
  const userMessageChannelRequest = useUserMessageChannelListRequest({
    params: {
      limit: 100
    }
  })

  const isLoading = userRequest.isInitialLoading || userRoleListRequest.isInitialLoading
  const isError = userRequest.isError || userRoleListRequest.isError
  const error = userRequest.error || userRoleListRequest.error

  const saveRequest = useEditUserRequest({
    id,
    options: {
      onSuccess: () => {
        navigate(-1)
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    },
  })

  const onSubmit = (data: UserEditState) => {
    saveRequest.mutate(data)
  }

  const initialValues: Partial<UserEditState> = userRequest.data
    ? {
      name: userRequest.data?.name,
      email: userRequest.data?.email,
      phone: userRequest.data?.phone,
      roleId: userRequest.data?.roleId,
      messageChannel: userRequest.data?.messageChannel,
    }
    : {}

  const roles = (userRoleListRequest.data?.rows || []).filter(
    (role) => role.name !== UserRoles.ServiceAccount
  )
  const messageChannels: { displayName: string, value: UserMessageChannels }[] = (userMessageChannelRequest.data?.rows || [])
    .filter((channel) => Boolean(channel.isActive))
    .map((channel) => ({ displayName: channel.displayName, value: channel.name }))


  const props = {
    onSubmit,
    roles,
    initialValues,
    isEditing: saveRequest.isLoading,
    messageChannels,
  }

  return (
    <DataLoad
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const EditUser: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <EntityHeader
        title="Edit user"
      />
      <Box width="500px" mt={2}>
        <UserResourceLoader userId={parseInt(id, 10)}>
          <UserEditForm />
        </UserResourceLoader>
      </Box>
    </HasSidebarLayout>
  )
}

export default EditUser
