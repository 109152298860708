import { FC, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useNavigate, useParams } from "react-router-dom"
import {
  DataLoad,
  EntityHeader,
  HasSidebarLayout,
  WagnerDeviceFirmwareForm,
} from "src/components"
import { useEditWagnerDeviceFirmwareRequest, useWagnerDeviceFirmwareRequest } from "src/hooks/api"
import { UpdateFirmwarePayload } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { Entities, WagnerDeviceFirmwareDeviceTypeEnum } from "src/enums"

const EditWagnerDeviceFirmware: FC = () => {
  const params  = useParams()
  const { id } = params as { id: string }
  const firmwareId = parseInt(id, 10)
  const { isInitialLoading, data: firmware, isError, error } = useWagnerDeviceFirmwareRequest({
    id: firmwareId,
  })
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const editRequest = useEditWagnerDeviceFirmwareRequest({
    id: firmwareId,
    options: {
      onSuccess: () => {
        addMessage({ text: 'Firmware updated successfully', type: 'success' })
        navigate(-1)
        queryClient.invalidateQueries([Entities.WagnerDeviceFirmware, firmwareId])
      },
      onError: (error) => {
        addMessage({ text: error.message, type: 'error' })
      },
    }
  })
  const getTitle = useMemo(() => {
    const deviceTypeDictinary: Record<string, string> = {
      [WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber]: 'Data Grabber',
      [WagnerDeviceFirmwareDeviceTypeEnum.RangeExtender]: 'Range Extender',
    }
    const version = firmware?.version || ''
    const deviceType = deviceTypeDictinary[firmware?.deviceType || WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber]
    return `Edit ${deviceType} Firmware - ${version}`
  }, [firmware])
  document.title = `Climit Admin Panel - ${getTitle}`
  const updateFirmware = (data: UpdateFirmwarePayload) => {
    editRequest.mutate(data)
  }

  return (
    <HasSidebarLayout>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityHeader title={getTitle} />
        </Grid>
        <Grid item>
          <DataLoad
            isLoading={isInitialLoading}
            isError={isError}
            errorMessage={error?.message}
          >
            <Box width="100%" maxWidth="1000px">
              <WagnerDeviceFirmwareForm
                initialValues={{
                  version: firmware?.version || '',
                  deviceType: firmware?.deviceType || WagnerDeviceFirmwareDeviceTypeEnum.DataGrabber,
                  description: firmware?.description,
                  attachments: firmware?.attachments || [],
                }}
                onSubmit={updateFirmware}
              />
            </Box>
          </DataLoad>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default EditWagnerDeviceFirmware
