import format from "date-fns/format"
import { GridColDef } from "@mui/x-data-grid-premium"
import { TableCell } from "@mui/material"
import { ProjectSection, Sensor } from "src/api"
import { capitalizeFirstLetter } from "src/utils/formatters"
import { ProjectSectionStatusEnum } from "src/enums"
import { DynamicColumn, InnerLink } from "../ui"

namespace SectionColumns {
  export const Name: GridColDef<ProjectSection> = {
    field: "name",
    headerName: "Name",
    minWidth: 180,
    type: "string",
    editable: false,
    renderCell: ({ value, row }) => (
      <InnerLink to={`/sections/${row.id}`}>{value}</InnerLink>
    )
  }

  export const DateCreated: GridColDef<ProjectSection> = {
    field: "createdAt",
    headerName: "Date Created",
    minWidth: 180,
    type: "date",
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const Status: GridColDef<ProjectSection> = {
    field: "status",
    headerName: "Status",
    minWidth: 100,
    type: "string",
    valueGetter: ({ row }) => row.status === ProjectSectionStatusEnum.Active ? "Active" : "Archived",
  }

  export const DateArchived: GridColDef<ProjectSection> = {
    field: "archivedAt",
    headerName: "Date Archived",
    minWidth: 180,
    type: "date",
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const AmountOfFloorcloudSensors: GridColDef<ProjectSection> = {
    field: "row.projectSectionsSensors?.length",
    headerName: "Amount of Climit sensors",
    minWidth: 230,
    type: "string",
    valueGetter: ({ row }) => {
      if(!row.projectSectionsSensors?.length) return '-'
      const activeSensorsCount = row.projectSectionsSensors.filter(sensor => sensor.isActive).length
      const archivedSensorsCount = row.projectSectionsSensors.length - activeSensorsCount
      return `${activeSensorsCount} active (${archivedSensorsCount} archived)`
    },
  }

  export const AmountOfRapidRHSensors: GridColDef<ProjectSection> = {
    field: "row.wagnerProbes?.length",
    headerName: "Amount of RapidRH sensors",
    minWidth: 230,
    type: "string",
    valueGetter: ({ row }) => row.wagnerProbes?.length || '-'
  }
}

export namespace DefaultSectionColumns {
  export const ProjectName: DynamicColumn<ProjectSection> = {
    field: 'project.name',
    headerName: 'Project',
    type: 'string',
    innerLink: (row) => `/projects/${row.project?.id}`,
  }

  export const ContractorName: DynamicColumn<ProjectSection> = {
    field: 'project.contractor.name',
    headerName: 'Contractor',
    type: 'string',
    innerLink: (row) => `/contractors/${row.project?.contractor?.id}`,
  }

  export const Status: DynamicColumn<ProjectSection> = {
    field: 'status',
    headerName: 'Status',
    type: 'custom',
    component: ({
      row,
      cellProps,
    }) => {
      const status = capitalizeFirstLetter(row.status || '')
      return (
        <TableCell align={cellProps?.align}>
          { status || '-' }
        </TableCell>
      )
    },
  }

  export const NumberOfRapidRH: DynamicColumn<ProjectSection> = {
    field: '',
    headerName: 'Number of RapidRH',
    type: 'custom',
    component: ({
      row,
      cellProps,
    }) => {
      const number = row.wagnerProbes?.length || 0
      return (
        <TableCell align={cellProps?.align}>
          { number }
        </TableCell>
      )
    },
  }

  export const NumberOfSensors: DynamicColumn<ProjectSection> = {
    field: '',
    headerName: 'Number of sensors',
    type: 'custom',
    component: ({
      row,
      cellProps,
    }) => {
      const sensors = (row.projectSectionsSensors || []).reduce<Sensor[]>((acc, pss) => {
        if (pss.sensor) {
          const hasInResult = acc.some((item) => item.uniqueId === pss.sensor?.uniqueId)
          if (!hasInResult) acc.push(pss.sensor)
        }
        return acc
      }, [])
      return (
        <TableCell align={cellProps?.align}>
          { sensors.length || 0 }
        </TableCell>
      )
    },
  }
}

export default SectionColumns