import React, { FC, PropsWithChildren } from "react"
import { Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  ProductList,
  SearchForm,
  ProductListFilters,
  PropsInjector,
} from "src/components"
import {
  useManufacturerRequest,
  useProductListRequest,
  useProductTypeListRequest,
  useFilteredManufacturerListRequest,
} from "src/hooks/api"
import { useQueryParamsState } from "src/hooks/ui"
import { Product } from "src/api"
import { SearchParamsKeys } from "src/enums"

const FiltersDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const queryParams = useQueryParamsState()

  const manufacturerId = queryParams[SearchParamsKeys.Manufacturer]

  const currentManufacturerRequest = useManufacturerRequest({
    id: parseInt(manufacturerId, 10),
    options: {
      enabled: Boolean(manufacturerId),
    },
  })

  const projectTypesRequest = useProductTypeListRequest({
    params: { limit: 9999 },
  })

  const manufacturersRequest = useFilteredManufacturerListRequest()

  const findManufacturersByName = (name: string) => {
    if (name?.length >= 3) manufacturersRequest.mutate({ name })
  }

  const isLoading =
    projectTypesRequest.isInitialLoading || currentManufacturerRequest.isInitialLoading
  const isError =
    projectTypesRequest.isError ||
    manufacturersRequest.isError ||
    currentManufacturerRequest.isError
  const error =
    projectTypesRequest.error ||
    manufacturersRequest.error ||
    currentManufacturerRequest.error

  const props = {
    projectTypes: projectTypesRequest.data?.rows || [],
    manufacturers: manufacturersRequest.data?.rows || [],
    manufacturersLoading: manufacturersRequest.isLoading,
    currentManufacturer: currentManufacturerRequest?.data || null,
    findManufacturersByName,
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      {isLoading ? null : (
        <PropsInjector props={props}>{children}</PropsInjector>
      )}
    </DataLoad>
  )
}

const Products: FC = () => {
  document.title = 'Climit Admin Panel - Products'
  const params = useQueryParamsState()
  const { isInitialLoading, isError, data } = useProductListRequest({
    params,
  })
  const result: Product[] = data?.rows || []
  const count: number = data?.count || 0
  return (
    <HasSidebarLayout>
      <DataLoad isLoading={false} isError={isError}>
        <Grid container direction="column" alignItems="stretch">
          <ListHeader title="Published products" />
        </Grid>
        <Grid item container flexDirection="column" spacing={2} mb={2}>
          <Grid item>
            <SearchForm placeholder="Enter a product name" />
          </Grid>
          <Grid item>
            <FiltersDataLoader>
              <ProductListFilters />
            </FiltersDataLoader>
          </Grid>
        </Grid>
        <ProductList loading={isInitialLoading} data={result} count={count} />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default Products
