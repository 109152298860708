import React, { FC, PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  HasSidebarLayout,
  UserNewForm,
  UserNewState,
  DataLoad,
  PropsInjector,
  InnerLink,
  EntityHeader,
} from 'src/components'
import {
  useUserRoleListRequest,
  useContractorListRequest,
  useNewUserRequestV2,
} from 'src/hooks/api'
import { useAddPopupMessage, useQueryParams } from 'src/hooks/ui'
import { UserRoles } from 'src/enums'

const UserResourceLoader: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const addMessage = useAddPopupMessage()
  const [queryParams, , removeQueryParams] = useQueryParams()

  const params = { limit: 9999 }

  const rolesRequest = useUserRoleListRequest({ params })

  const contractorsRequest = useContractorListRequest({ params })

  const saveRequest = useNewUserRequestV2({
    options: {
      onSuccess: async (result) => {
        const { hasClone, user } = result
        if (hasClone) {
          addMessage({
            text: <Typography>
              Contractor has the archived user with the same credentials
              <InnerLink to={`/users/${user.id}`}>{` - ${user.name}`}</InnerLink>
            </Typography>,
            type: 'warning',
          })
        } else {
          await removeQueryParams(Object.keys(queryParams))
          navigate(-1)
        }
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: 'error' })
      },
    },
  })

  const isLoading = rolesRequest.isInitialLoading || contractorsRequest.isInitialLoading
  const isError = rolesRequest.isError || contractorsRequest.isError
  const error = rolesRequest.error || contractorsRequest.error

  const onSubmit = (data: UserNewState) => {
    saveRequest.mutate(data)
  }

  const userRoles = (rolesRequest.data?.rows || []).filter(
    (role) => role.name !== UserRoles.ServiceAccount
  )

  const props = {
    initialValues: { ...queryParams },
    onSubmit,
    userRoles,
    contractors: contractorsRequest?.data?.rows || [],
    isCreating: saveRequest.isLoading
  }

  return (
    <DataLoad
      isLoading={isLoading}
      isError={isError}
      errorMessage={error?.message}
    >
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const NewUser: FC = () => {
  document.title = 'Climit Admin Panel - New user'
  return (
    <HasSidebarLayout>
      <EntityHeader
        title="Create new user"
      />
      <Box width="500px" mt={2}>
        <UserResourceLoader>
          <UserNewForm />
        </UserResourceLoader>
      </Box>
    </HasSidebarLayout>
  )
}

export default NewUser
