import React, { FC, PropsWithChildren } from "react"
import { useParams } from "react-router-dom"
import { Button, Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  EntityHeader,
  TabGroupFilter,
  InnerLink,
  ContractorInfo,
  UsersOfContractor,
  ContractorProjects,
  ContractorSensors,
  ContractorBranches,
  ContractorReports,
  ContractorTasks,
  StatefulDialog,
  ConfirmContractorDeactivationDialog,
  ContractorEventList,
  PropsInjector,
  ContractorDocumentList,
  ContractorAlertList,
} from "src/components"
import { useContractorRequest, useUserRoleListRequest } from "src/hooks/api"
import { Contractor, UserRole } from "src/api"
import { DialogNames, SearchParamsKeys } from "src/enums"
import { useDeactivateContractor } from "src/hooks/api/useDeactivateContractor"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"
import { ContractorWagnerProbeList } from "src/components/sections/contractor/contractorWagnerProbeList"
import { useActivateContractor } from "../hooks/api/useActivateContractor"

interface ContractorShowContentProps {
  contractorId?: number;
  userRoles?: UserRole[];
  contractor?: Contractor;
  activate?: () => void;
  deactivate?: () => void;
  isDeactivationInProgress?: boolean;
  isActivationInProgress?: boolean;
}

const getTabs = (
  contractorId: number,
  userRoles: UserRole[],
  contractor: Contractor,
) => ([
  {
    status: "Users",
    content: (
      <UsersOfContractor contractorId={contractorId} userRoles={userRoles} />
    ),
  },
  {
    status: "Projects",
    content: <ContractorProjects contractorId={contractorId} />,
  },
  {
    status: "Sensors",
    content: <ContractorSensors contractorId={contractorId} />,
  },
  {
    status: "Wagner Probes",
    content: <ContractorWagnerProbeList contractorId={contractorId} />,
  },
  ...(contractor.isRoot === true ? [
    {
      status: "Branches",
      content: <ContractorBranches branches={contractor.branches || []} />
    }
  ] : []),
  {
    status: "Reports",
    content: <ContractorReports contractorId={contractorId} />
  },
  {
    status: "Tasks",
    content: <ContractorTasks contractorId={contractorId} />
  },
  {
    status: "Events",
    content: <ContractorEventList contractorId={contractorId} />,
  },
  {
    status: "Documents",
    content: <ContractorDocumentList contractorId={contractorId} />,
  },
  {
    status: "Alerts",
    content: <ContractorAlertList contractorId={contractorId} />,
  },
])

const ContractorShowContent: FC<ContractorShowContentProps> = ({
  contractorId,
  userRoles,
  contractor,
  activate,
  deactivate,
  isActivationInProgress,
  isDeactivationInProgress,
}) => {
  if (!contractor || !activate || !deactivate || !contractorId || !userRoles) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <EntityHeader
          title={contractor.name}
          actionBlockOnTheRight={<InnerLink
            to={`/contractors/${contractorId}/edit`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained">Edit Info</Button>
          </InnerLink>}
        />
      </Grid>
      <Grid item container xs={12}>
        <ContractorInfo
          contractor={contractor}
          isActivationInProgress={isActivationInProgress as boolean}
          isDeactivationInProgress={isDeactivationInProgress as boolean}
          activate={activate}
          deactivate={deactivate}
        />
      </Grid>
      <Grid item pt={4} xs={12}>
        <TabGroupFilter
          name={SearchParamsKeys.Tab}
          statusList={getTabs(contractorId, userRoles, contractor)}
          paramsToRemove={[
            SearchParamsKeys.Page,
            SearchParamsKeys.RowsPerPage,
            SearchParamsKeys.Contractor,
          ]}
        />
      </Grid>
    </Grid>
  )
}

const ContractorDataLoader: FC<PropsWithChildren<{ contractorId: number }>> = ({
  children,
  contractorId,
}) => {
  const addPopupMessage = useAddPopupMessage()
  const { openDialog, closeDialog } = useDialog(DialogNames.ConfirmContractorDeactivation)
  const contractorRequest = useContractorRequest({ id: contractorId })
  document.title = `Climit Admin Panel - Contractor ${contractorRequest.data?.name || ''}`

  const userRoleListRequest = useUserRoleListRequest({
    params: { limit: 9999 },
  })

  const isLoading = contractorRequest.isInitialLoading
    || userRoleListRequest.isInitialLoading

  const isError = contractorRequest.isError || userRoleListRequest.isError

  const roles: UserRole[] = userRoleListRequest?.data?.rows || []

  const { mutate: activate, isLoading: isActivationInProgress } = useActivateContractor({
    options: {
      onSuccess: () => {
        contractorRequest.refetch()
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      },
    },
  })

  const { mutate: deactivate, isLoading: isDeactivationInProgress } = useDeactivateContractor({
    options: {
      onSuccess: () => {
        contractorRequest.refetch()
        closeDialog()
      },
      onError: ({ message: text }) => {
        addPopupMessage({ text, type: "error" })
      },
    },
  })

  const props: ContractorShowContentProps = {
    contractorId,
    contractor: contractorRequest.data as Contractor,
    userRoles: roles,
    activate: () => activate({ id: contractorId }),
    deactivate: () => openDialog(),
    isActivationInProgress: isActivationInProgress,
    isDeactivationInProgress: isDeactivationInProgress,
  }

  return (
    <DataLoad isLoading={isLoading} isError={isError}>
      <PropsInjector props={props}>
        {children}
        <StatefulDialog
          name={DialogNames.ConfirmContractorDeactivation}
          wrapContent={false}
        >
          <ConfirmContractorDeactivationDialog
            contractor={contractorRequest.data?.name || ''}
            onSubmit={() => deactivate({ id: contractorId })}
            isLoading={isDeactivationInProgress}
          />
        </StatefulDialog>
      </PropsInjector>
    </DataLoad>
  )
}

const ContractorShow: FC = () => {
  const params = useParams()
  const { id } = params as { id: string }

  return (
    <HasSidebarLayout>
      <ContractorDataLoader contractorId={parseInt(id, 10)}>
        <ContractorShowContent />
      </ContractorDataLoader>
    </HasSidebarLayout>
  )
}

export default ContractorShow
