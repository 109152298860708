import { FC } from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import {
  EntityHeader,
  HasSidebarLayout,
  WagnerDeviceFirmwareForm,
} from "src/components"
import { useNewWagnerDeviceFirmwareRequest } from "src/hooks/api"
import { CreateFirmwarePayload } from "src/api"
import { useAddPopupMessage } from "src/hooks/ui"
import { useNavigate } from "react-router-dom"

const NewWagnerDeviceFirmware: FC = () => {
  document.title = 'Climit Admin Panel - New Wagner Deivce Firmware'
  const addMessage = useAddPopupMessage()
  const navigate = useNavigate()
  const creationRequest = useNewWagnerDeviceFirmwareRequest({
    options: {
      onSuccess: () => {
        addMessage({ text: 'Firmware created successfully', type: 'success' })
        navigate(-1)
      },
      onError: (error) => {
        addMessage({ text: error.message, type: 'error' })
      },
    }
  })
  const createFirmware = (data: CreateFirmwarePayload) => {
    creationRequest.mutate(data)
  }

  return (
    <HasSidebarLayout>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <EntityHeader title="New Wagner Device Firmware" />
        </Grid>
        <Grid item>
          <Box width="100%" maxWidth="1000px">
            <WagnerDeviceFirmwareForm onSubmit={createFirmware} />
          </Box>
        </Grid>
      </Grid>
    </HasSidebarLayout>
  )
}

export default NewWagnerDeviceFirmware
