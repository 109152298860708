import { FC, PropsWithChildren, useMemo, useState } from "react"
import { CircularProgress, Grid } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid-premium"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  PropsInjector,
  ReportList,
} from "src/components"
import { useRegenerateReportLinkRequest, useReportListRequest } from "src/hooks/api"
import { useAddPopupMessage, useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { Report } from "src/api"
import { defaultRowsValuePerPage } from "src/config"
import { ReportColumnsDataGrid } from "src/components/sections/reports/tableColumns"

interface ReportsViewProps {
  reports?: Report[];
  reportsCount?: number;
  reportsLoading?: boolean;
  isLoading?: boolean;
  queryParams?: Record<string, string>;
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
  defaultRowsPerPageOptions?: number[];
  refetchReportList?: () => void;
}

const ReportsView: FC<ReportsViewProps> = ({
  reports = [],
  reportsCount = 0,
  isLoading = false,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions,
  refetchReportList,
}) => {
  const [currentRow, setCurrentRow] = useState<Report | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const addPopupMessage = useAddPopupMessage()
  const regenerateReportLinkRequest = useRegenerateReportLinkRequest()
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    row: Report
  ) => {
    setCurrentRow(row)
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setCurrentRow(null)
    setMenuAnchorEl(null)
  }

  const columns = useMemo(() => ([
    ReportColumnsDataGrid.Name,
    ReportColumnsDataGrid.Type,
    ReportColumnsDataGrid.Components,
    ReportColumnsDataGrid.ReportProject,
    ReportColumnsDataGrid.ReportSection,
    ReportColumnsDataGrid.DateFrom,
    ReportColumnsDataGrid.DateTo,
    ReportColumnsDataGrid.Duration,
    ReportColumnsDataGrid.DateOfGeneration,
    ReportColumnsDataGrid.TimeOfGeneration,
    ReportColumnsDataGrid.DayOfWeek,
    ReportColumnsDataGrid.Contractor,
    ReportColumnsDataGrid.Author,
    ReportColumnsDataGrid.ProjectArchivationDate,
    ReportColumnsDataGrid.AvailabilitySummary,
    ReportColumnsDataGrid.NameHadChanged,
    ReportColumnsDataGrid.EventsIncluded,
    ReportColumnsDataGrid.EventsAmount,
    ReportColumnsDataGrid.EventsWithPhoto,
    ReportColumnsDataGrid.EventsWithNotes,
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 30,
      getActions: ({ row }: { row: any }) => [
        <GridActionsCellItem
          icon={<MoreVertIcon />}
          label="Actions"
          className="textPrimary"
          onClick={(e: any) => handleOpenMenu(e, row)}
          color="inherit"
        />,
      ],
    },
  ]), [])
  const regenerateReportLink = () => {
    const reportId = currentRow?.id
    if (!reportId) {
      addPopupMessage({ text: 'Please select a report', type: 'warning' })
      return
    }
    regenerateReportLinkRequest.mutate(reportId, {
      onSuccess: () => {
        addPopupMessage({ text: 'Link was successfully updated', type: 'success' })
        handleCloseMenu()
        if (refetchReportList) refetchReportList()
      },
      onError: (error) => {
        addPopupMessage({ text: error?.message, type: 'error' })
      }
    })
  }
  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Reports" />
      </Grid>
      <ReportList
        isLoading={isLoading}
        data={reports}
        count={reportsCount}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        defaultRowsPerPageOptions={defaultRowsPerPageOptions}
        columns={columns}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => regenerateReportLink()}
          disabled={currentRow?.status === 'in_progress' || regenerateReportLinkRequest.isLoading}
        >
          Regenerate a link {currentRow?.status === 'in_progress' ? '(in progress)' : ''}
          {regenerateReportLinkRequest.isLoading ? <CircularProgress size={26} sx={{ marginLeft: '5px' }} /> : null }
        </MenuItem>
      </Menu>
    </>
  )
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ReportDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error, refetch } = useReportListRequest({
    params,
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const props: ReportsViewProps = {
    reports: data?.rows || [],
    reportsCount: data?.count || 0,
    reportsLoading: isInitialLoading,
    isLoading: isInitialLoading,
    refetchReportList: refetch,
    queryParams,
    setQueryParams,
    defaultRowsPerPageOptions
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const Reports: FC = () => {
  document.title = 'Climit Admin Panel - Reports'
  return (
    <HasSidebarLayout>
      <ReportDataLoader>
        <ReportsView />
      </ReportDataLoader>
    </HasSidebarLayout>
  )
}

export default Reports
