import React, { FC } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { DataLoad, HasSidebarLayout, ListHeader } from "src/components"
import { ManufacturerAnnouncementCard } from "src/components"
import { useManufacturerAnnouncementListRequest } from "src/hooks/api"
import { ManufacturerAnnouncement } from "src/api"

interface ManufacturerAnnouncementListProps {
  announcements: ManufacturerAnnouncement[]
}

const ManufacturerAnnouncementList: FC<ManufacturerAnnouncementListProps> = ({
  announcements,
}) => {
  const renderAnnouncements = () => {
    if (announcements.length === 0) {
      return <Typography variant="body1">There are no announcements</Typography>
    }

    return announcements.map((announcement) => (
      <Grid item key={`announcement_${announcement.id}`} width="100%">
        <ManufacturerAnnouncementCard announcement={announcement} />
      </Grid>
    ))
  }
  return (
    <Grid container direction="column" alignItems="stretch">
      <ListHeader title="GangBox">
        <Link to="/manufacturer-announcements/new" style={{ textDecoration: "none" }}>
          <Button role="link" variant="contained">
            Add new
          </Button>
        </Link>
      </ListHeader>
      <Grid container mt={2} flexDirection="column" spacing={2}>
        {renderAnnouncements()}
      </Grid>
    </Grid>
  )
}

const ManufacturerAnnouncementListLoader: FC = () => {
  document.title = 'Climit Admin Panel - GangBox'
  const { isInitialLoading, isError, error, data } = useManufacturerAnnouncementListRequest({
    params: { limit: 100 }
  })
  const announcements = data?.rows || []

  return (
    <HasSidebarLayout>
      <DataLoad
        isLoading={isInitialLoading}
        isError={isError}
        errorMessage={error?.message || ''}
      >
        <ManufacturerAnnouncementList
          announcements={announcements}
        />
      </DataLoad>
    </HasSidebarLayout>
  )
}

export default ManufacturerAnnouncementListLoader
